<template>
  <div class="container min-height flex-column justify-content-spaceBetween align-items-center">
    <div class="width-100 flex-column justify-content-start align-items-center">
      <top></top>
      <div class="container-box copyrightRegistration flex-column justify-content-start align-items-center">
        <div class="width-100 flex-row justify-content-start align-items-center border-b">
          <p class="fs-super-big black fw-mid">需求发布</p>
        </div>
        <div class="width-60 form-box">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label="版权分类" prop="copyrightTypeOn">
              <el-select v-model="form.copyrightTypeOn" placeholder="请选择版权分类">
                <el-option v-for="(item, index) in bqsbCopyrightTypeDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="版权名称" prop="title">
              <el-input v-model="form.title" placeholder="请输入版权名称"></el-input>
            </el-form-item>
            <el-form-item label="版权用途" prop="copyrightUsage">
              <el-select v-model="form.copyrightUsage" placeholder="请选择版权用途">
                <el-option v-for="(item, index) in tranCopyrightUsageDist" :key="index" :label="item.text" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用对象" prop="usageType">
              <el-radio-group v-model="form.usageType">
                <el-radio v-for="(item, index) in tranUsageType" :key="index" :label="item.value" border>{{ item.text }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item v-if="form.usageType === 1" label="联系人" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请输入联系人"></el-input>
            </el-form-item>
            <el-form-item v-if="form.usageType === 2" label="企业名称" prop="companyName">
              <el-input v-model="form.companyName" placeholder="请输入企业名称"></el-input>
            </el-form-item>
            <el-form-item label="联系方式" prop="phone">
              <el-input v-model="form.phone" placeholder="请输入联系方式"></el-input>
            </el-form-item>
            <el-form-item label="需求说明">
              <el-input type="textarea" v-model="form.needRemarks" :autosize="{ minRows: 10, maxRows: 15 }" placeholder="请根据你的需求填写说明"></el-input>
            </el-form-item>
            <el-form-item label="辅助资料" prop="">
              <!-- <el-upload
                class="avatar-uploader"
                action="https://jsonplaceholder.typicode.com/posts/"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.copyrightImage" :src="form.copyrightImage" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload> -->
              <el-upload
                :limit="1"
                class="avatar-uploader"
                :before-upload="beforeImageUpload"
                :on-success="onCopyrightCardImageSuccess"
                :on-remove="onCopyrightCardImageRemove"
                :file-list="form.copyrightCardImages"
                :action="`${PROJECT_BASE_URL}/bqsb/util/upload/bqjy/3`"
                list-type="picture-card"
              >
                <i class="el-icon-plus" />
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit">提交</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import top from '../components/top';
import bottom from '../components/footer';
import { getDict } from '@/api/dict';
import * as _ from 'lodash';
import { postTranCopyrightDemand, getCopyrightDemandInfo, putTranCopyrightDemand } from '@/api/tranCopyrightDemand';

// TODO 页面首次进来是，输入框显示异常

export default {
  name: 'releaseDemand',
  components: {
    top,
    bottom
  },
  data() {
    return {
      checked: false,
      dialogVisible: false,
      search: '',
      form: {
        copyrightTypeOn: '', // 版权分类
        title: '', // 版权名称
        copyrightUsage: '', // 版权用途
        usageType: 1, // 使用对象
        companyName: '',
        phone: '', // 联系方式
        needRemarks: '', // 需求说明
        otherFilesObj: '', // 辅助资料
        otherFiles: '' // 辅助资料
      },
      rules: {
        copyrightTypeOn: [{ required: true, message: '请选择版权分类', trigger: 'change' }],
        title: [{ required: true, message: '请输入版权名称', trigger: 'blur' }],
        copyrightUsage: [{ required: true, message: '请选择权力种类', trigger: 'change' }],
        usageType: [{ required: true, message: '请选择使用对象', trigger: 'change' }],
        companyName: [{ required: true, message: '请输入内容', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入联系方式', trigger: 'blur' }],
        needRemarks: [
          {
            required: true,
            message: '请根据你的需求填写说明',
            trigger: 'blur'
          }
        ]
      },
      bqsbCopyrightTypeDist: [],
      tranCopyrightUsageDist: [],
      tranUsageType: [],
      PROJECT_BASE_URL: process.env.VUE_APP_SERVE_PATH,
      params: {
        params: {
          contactMsg: 'true'
        }
      }
    };
  },
  async created() {
    await this.setDist();
    if (this.$route.query.id) {
      this.updateForm(this.$route.query.id)
    }
  },
  methods: {
    async setDist() {
      return Promise.all([
        getDict('bqsb_copyright_type'),
        getDict('tran_copyright_usage'),
        getDict('tran_usage_type')
      ]).then((rows) => {
        this.bqsbCopyrightTypeDist = rows[0];
        this.tranCopyrightUsageDist = rows[1];
        this.tranUsageType = rows[2];
      });
    },
    async onSubmit() {
      const dataForm = this.$refs.form;
      await dataForm.validate().then(async () => {
        if (this.form.id) {
          console.log('this.form.otherFilesObj:', this.form.otherFilesObj);
          if (this.form.otherFilesObj) {
            this.form.otherFiles = this.form.otherFilesObj.map((row) => {
              if (row.response) {
                return row.response.data.targetFileUrl;
              }
            }).join(',');
          }
          await putTranCopyrightDemand(this.form);
        }else {
          this.form.otherFiles = this.form.otherFilesObj.map((row) => row.response.data.targetFileUrl).join(',');
          await postTranCopyrightDemand(this.form);
        }
        this.$router.go(-1);
      },() => {
        // TODO
      })
    },
    onCopyrightCardImageSuccess(res, file, fileList) {
      this.form.otherFilesObj = fileList;
    },
    onCopyrightCardImageRemove(file, fileList) {
      this.form.otherFilesObj = fileList;
    },
    beforeImageUpload(file) {
      const fileType = ['image/png', 'image/jpeg', 'image/jpg', 'image/pjpeg', 'image/x-png'];
      console.log('file.type:', file);
      if (/#/.test(file.name)) {
        this.$message.error('图片名称不可包含#');
      }
      const fileTypeCheck = !fileType.includes(file.type);
      if (!fileType.includes(file.type)) {
        this.$message.error('上传图片只能是 JPG/JPEG/PNG 格式!');
      }
      const isLt2M = file.size / 1024 / 1024 < 3;
      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 3MB!');
      }
      return isLt2M && !fileTypeCheck && !/#/.test(file.name);
    },
    async updateForm(id) {
      const info = await getCopyrightDemandInfo(id, this.params);
      this.form = {
        ..._.pick(info.data, ['id', 'copyrightTypeOn', 'title', 'copyrightUsage', 'usageType', 'companyName', 'phone', 'needRemarks', 'otherFiles']),
        copyrightCardImages: info.data.otherFiles.split(',').map(url => {
          return {
            url: `${this.PROJECT_BASE_URL}${url}`
          }
        }),
        copyrightUsage: Number(info.data.copyrightUsage),
        copyrightTypeOn: Number(info.data.copyrightTypeOn),
        usageType: Number(info.data.usageType),
      }
    }
  }
};
</script>

<style lang="less" scoped>
.container {
  background: #f3f9ff;
  .container-box {
    margin: 40px 0;
    padding: 30px;
    border-radius: 10px;
    background: white;
    .icon-img {
      width: 48px;
      margin-left: 20px;
    }
    .form-box {
      padding: 60px 0;
    }
  }
}
</style>
<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-form-item__label,
.el-input__inner,
.el-radio__label,
.el-textarea__inner,
.el-form-item__error {
  font-size: 18px;
}
.copyrightRegistration .el-input__inner {
  border: 1px solid #dcdfe6 !important;
  height: 3rem;
}
.copyrightRegistration .el-form-item {
  margin-bottom: 60px;
}

.copyrightRegistration .el-form-item__content {
  margin-left: 150px !important;
}
.copyrightRegistration .el-select {
  width: 100%;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio__input {
  opacity: 0 !important;
}
.copyrightRegistration .el-radio {
  padding-left: 0 !important;
  padding-right: 25px !important;
  margin-right: 10px !important;
}

.copyrightRegistration .el-button--primary {
  padding: 20px 80px;
}
.copyrightRegistration .el-button--primary span {
  font-size: 20px;
}
</style>
